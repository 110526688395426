import React, {useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import {useMediaPredicate} from "react-media-hook";

const Beneficiaries = () => {
    const [allBeneficiaries, setAllBeneficiaries] = useState([])
    const [myBeneficiaries, setMyBeneficiaries] = useState([])
    const [dataRows, setDataRows] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})
    const [nickname, setNickname] = useState("")
    const [accountName, setAccountName] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountType, setAccountType] = useState("Checking")
    const [editedSuccessfully, setEditedSuccessfully] = useState(false)
    const [editFailed, setEditFailed] = useState(false)
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createFailed, setCreateFailed] = useState(false)
    const [deletedSuccessfully, setDeletedSuccessfully] = useState(false)
    const [deleteFailed, setDeleteFailed] = useState(false)

    const editFormRef = useRef();
    const createFormRef = useRef();
    // const {width, height} = useViewport()
    const biggerThan768 = useMediaPredicate("(min-width: 760px)");

    console.log({biggerThan768})

    const columns = [
        {
            label: "Nickname",
            field: "nickname",
            sort: "asc",
            width: 150,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Account Name",
            field: "accountName",
            sort: "asc",
            width: 270,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Account Number",
            field: "accountNumber",
            sort: "asc",
            width: 200,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Account Type",
            field: "accountType",
            sort: "asc",
            width: 100,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Action",
            field: "action",
            width: 300,
            attributes: { style: {fontWeight: '700'}}
        },
    ]

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)


    useEffect(() => {
        fetch(`https://aang.quintessentialbank.com/api/beneficiary/all/1/1000`)
            .then(response => response.json())
            .then(response => {
                let res = response
                res?.data?.docs?.forEach((item, index) => {
                    item.id = (
                        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.id}</div>
                    );
                    item.action = (
                        <div style={{ display: "flex", justifyContent: "space-between", width: 150 }}>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#2f4395",
                                    width: "45%",
                                    textAlign: "center",
                                    height: 30,
                                }}
                                onClick={() => selectUserToEdit(item?.accountNumber)}
                            >
                                Edit
                            </div>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#fb6262",
                                    width: "50%",
                                    textAlign: "center",
                                    height: 30,
                                }}
                                onClick={() => selectUserToDelete(item?.accountNumber)}
                            >
                                Delete
                            </div>
                        </div>
                    );
                })
                console.log({res})
                let myDocs = res?.data?.docs?.filter(el => el?.email === JSON.parse(localStorage.getItem("authUser"))?.email)
                myDocs = myDocs?.filter(el => el?.status !== "deleted")
                setMyBeneficiaries(myDocs)
                setAllBeneficiaries(response?.data?.docs)
                setData({
                    columns,
                    rows: myDocs
                })
            })
            .catch(error => {
                console.log({error})
            })
    },[])

    async function handleValidSubmit(event, values) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            email: obj?.email,
            accountNumber: values?.accountNumber,
            payload: values
        }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/beneficiary/edit", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setEditedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setEditFailed(true)
        }
    }


    async function handleValidCreate(event, values) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            email: obj?.email,
            accountNumber: values?.accountNumber,
            accountName: values?.accountName,
            accountType: values?.accountType,
            nickname: values?.nickname,
        }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/beneficiary/new", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setCreatedSuccessfully(true)
            console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setCreateFailed(true)
        }
    }

    async function handleDeleteBeneficiary(aNum) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(aNum)
        let toPost = {
            email: obj?.email,
            accountNumber: aNum,
            payload: { status: "deleted" }
        }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/beneficiary/edit", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setDeletedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setDeleteFailed(true)
        }
    }

    const selectUserToEdit = (accountNumber) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://aang.quintessentialbank.com/api/beneficiary/details/${obj?.email}/${accountNumber}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setNickname(res?.data[0]?.nickname)
                setAccountName(res?.data[0]?.accountName)
                setAccountNumber(res?.data[0]?.accountNumber)
                setAccountType(res?.data[0]?.accountType)
                toggleEditModal()
            })
            .catch(err => {
                console.log({err})
                toggleEditModal()
            })
        // setNickname()
    }

    const selectUserToDelete = (accountNumber) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://aang.quintessentialbank.com/api/beneficiary/details/${obj?.email}/${accountNumber}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setNickname(res?.data[0]?.nickname)
                setAccountName(res?.data[0]?.accountName)
                setAccountNumber(res?.data[0]?.accountNumber)
                setAccountType(res?.data[0]?.accountType)
                toggleDeleteModal()
            })
            .catch(err => {
                console.log({err})
                toggleDeleteModal()
            })
        // setNickname()
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Quintessential Bank" breadcrumbItem="Beneficiaries" />
                {
                    !biggerThan768 &&
                    <Row>
                        <Col><br/><br/></Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <button className="btn btn-primary mb-3 float-end"
                        onClick={toggleCreateModal}>
                            Add Beneficiary
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showCreateModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Add Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={createFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidCreate(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="nickname"
                                                    label="Nickname"
                                                    value={nickname}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="accountName"
                                                    label="Account Name"
                                                    value={accountName}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="accountNumber"
                                                    label="Account Number"
                                                    value={accountNumber}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="accountType" label="Account Type"
                                                         value={accountType}>
                                                    <option value="Checking">Checking</option>
                                                    <option value="Savings">Savings</option>
                                                    <option value="Fixed Deposit">Fixed Deposit</option>
                                                    <option value="Premium Finance Account">Premium Finance Account</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => createFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showEditModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Edit Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={editFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                >
                                <Row>
                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <div className="form-group">
                                            <AvField
                                                name="nickname"
                                                label="Nickname"
                                                value={nickname}
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <div className="form-group">
                                            <AvField
                                                name="accountName"
                                                label="Account Name"
                                                value={accountName}
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                required
                                            />
                                        </div>
                                    </div>
                                </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="accountNumber"
                                                    label="Account Number"
                                                    value={accountNumber}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="accountType" label="Gender"
                                                         value={accountType}>
                                                    <option value="male">Checking</option>
                                                    <option value="female">Savings</option>
                                                    <option value="others">Fixed Deposit</option>
                                                    <option value="others">Premium Finance Account</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => editFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showDeleteModal}
                            toggle={() => {
                                toggleDeleteModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Delete Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this beneficiary?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleDeleteBeneficiary(accountNumber)}
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                {editedSuccessfully ? (
                    <SweetAlert
                        title="Edit successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your beneficiary details have been updated!
                    </SweetAlert>
                ) : null}
                {editFailed ? (
                    <SweetAlert
                        title="Error in editing beneficiary"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {deletedSuccessfully ? (
                    <SweetAlert
                        title="Delete successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeletedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your beneficiary has been deleted!
                    </SweetAlert>
                ) : null}
                {deleteFailed ? (
                    <SweetAlert
                        title="Error in deleting beneficiary"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeleteFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {createdSuccessfully ? (
                    <SweetAlert
                        title="Beneficiary Successfully added"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreatedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        A new beneficiary has been added!
                    </SweetAlert>
                ) : null}
                {createFailed ? (
                    <SweetAlert
                        title="Error in adding beneficiary"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreateFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
            </div>

        </React.Fragment>
    )
}

export default Beneficiaries
