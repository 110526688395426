import React, {forwardRef, useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import {useMediaPredicate} from "react-media-hook";
import ReactToPrint, {useReactToPrint} from "react-to-print";

const TransactionHistory = () => {
    const [myLoans, setMyLoans] = useState([])
    const [myFDR, setMyFDR] = useState([])
    const [myInterbankTransfer, setMyInterbankTransfer] = useState([])
    const [myWireTransfer, setMyWireTransfer] = useState([])
    const [myTransactions, setMyTransactions] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})

    const biggerThan768 = useMediaPredicate("(min-width: 760px)");

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const columns = [
        {
            label: "Type",
            field: "type",
            sort: "asc",
            width: 150,
            attributes: { style: {fontWeight: '700', backgroundColor: '#2f4395', color: 'white'}}
        },
        {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 270,
            attributes: { style: {fontWeight: '700', backgroundColor: '#2f4395', color: 'white'}}
        },
        {
            label: "Amount",
            field: "amount",
            sort: "asc",
            width: 200,
            attributes: { style: {fontWeight: '700', backgroundColor: '#2f4395', color: 'white'}}
        },
        {
            label: "Status",
            field: "modifiedStatus",
            sort: "asc",
            width: 100,
            attributes: { style: {fontWeight: '700', backgroundColor: '#2f4395', color: 'white'}}
        },
    ]

    const DownloadButton = () => {
        return (
            <div columns={data.columns} data={data.rows} color="primary">
                Download
            </div>
        )
    }

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

    const getTransactions = async () => {
        let loans = await fetch(`https://aang.quintessentialbank.com/api/loans/user/${JSON.parse(localStorage.getItem("authUser"))?.email}`)
        loans = await loans.json()
        loans = loans.data

        let fdr = await fetch(`https://aang.quintessentialbank.com/api/FDR/user/${JSON.parse(localStorage.getItem("authUser"))?.email}`)
        fdr = await fdr.json()
        fdr = fdr.data

        let wireTransfer = await fetch(`https://aang.quintessentialbank.com/api/wire-transfer/user/${JSON.parse(localStorage.getItem("authUser"))?.email}`)
        wireTransfer = await wireTransfer.json()
        wireTransfer = wireTransfer.data

        let interbankTransfer = await fetch(`https://aang.quintessentialbank.com/api/interbank-transfer/user/${JSON.parse(localStorage.getItem("authUser"))?.email}`)
        interbankTransfer = await interbankTransfer.json()
        interbankTransfer = interbankTransfer.data


        for (let i=0; i<loans.length; i++){
            loans[i].name = loans[i]?.loanName
            loans[i].type = <p style={{fontWeight: '700'}}>Loan</p>
        }

        for (let i=0; i<fdr.length; i++){
            fdr[i].name = fdr[i]?.FDRName
            fdr[i].type = <p style={{fontWeight: '700'}}>Federal Reserve</p>
        }

        for (let i=0; i<wireTransfer.length; i++){
            wireTransfer[i].name = wireTransfer[i]?.accountName
            wireTransfer[i].type = <p style={{fontWeight: '700'}}>Wire Transfer</p>
        }

        for (let i=0; i<interbankTransfer.length; i++){
            interbankTransfer[i].name = interbankTransfer[i]?.accountName
            interbankTransfer[i].type = <p style={{fontWeight: '700'}}>Interbank Transfer</p>
        }

        let combined = [...loans,...fdr,...wireTransfer,...interbankTransfer]

        combined = combined?.filter(el => el?.status !== 'deleted')

        for (let i=0; i<combined?.length; i++){
            combined[i].modifiedStatus = <p className="text-uppercase"
            style={{
                color: combined[i]?.status === 'active'? 'blue':
                    combined[i]?.status === 'pending'? 'red':
                        combined[i]?.closed === 'closed'? 'green': 'darkviolet'
            }}>{combined[i]?.status}</p>
        }

        // console.log({loans})
        // console.log({fdr})
        // console.log({wireTransfer})
        // console.log({interbankTransfer})
        console.log({combined})
        return combined
    }

    useEffect(() => {
        getTransactions()
            .then(res => {
                let trans = res;
                trans.forEach((item, index) => {
                    item.id = (
                        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{index}</div>
                    );
                })
                setMyTransactions(trans)
                setData({
                    columns,
                    rows: trans
                })
            })
    },[])

    const TransactionTable = forwardRef((props, ref) => {
        return (
            <div ref={ref}>
                {
                    !biggerThan768 &&
                    <Row>
                        <Col><br/><br/></Col>
                    </Row>
                }
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <h3 style={{fontSize: '1.5rem'}}>Quintessential Bank TRANSACTION HISTORY</h3><br/>
                                <MDBDataTable responsive exportToCSV={true} hover bordered striped data={data} />
                                <p>Date: {new Date().toLocaleDateString()}</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    })


    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Quintessential Bank" breadcrumbItem="Transaction History" />
                {/*<button onClick={handlePrint}>Print this out!</button>*/}
                <Row>
                    <Col>
                        <ReactToPrint
                            trigger={() => <button className="btn btn-primary mb-3 mt-5 float-end">Print as PDF</button>}
                            content={() => componentRef.current}
                            bodyClass="p-4"
                        />
                    </Col>
                </Row>

                <TransactionTable ref={componentRef} />
            </div>

        </React.Fragment>
    )
}

export default TransactionHistory
