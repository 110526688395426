import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import {withRouter, Link, useParams} from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.png"

const ResetPasswordPage = props => {

  const [emailSuccessful, setEmailSuccessful] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [loading, setLoading] = useState(false);

  const { code } = useParams()

  // console.log({code})

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  async function handleValidSubmit(event, values) {
    // console.log({values})
    setLoading(true)

    if (values?.password !== values?.confirmPassword){
      setNoMatch(true)
      return
    }

    try {
      const response = await fetch(`https://aang.quintessentialbank.com/api/email/reset/${code}`,{
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      } );

      const result = await response.json();
      console.log("Result:", result);


      if (result.status === "success"){
        setLoading(false)
        setEmailSuccessful(true)
        setTimeout(() => {window.location.href = "/login"}, 3000)
      }
      else{
        setLoading(false)
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false)
      setEmailFailed(true)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} lg={5}>
              <div className="card overflow-hidden">
                <div className="text-center">
                  {/*<div className="bg-login-overlay"></div>*/}
                  <img src={logo} style={{width: 150, height: 150}} />
                  <div className="position-relative">
                    <h5 className="font-size-20" style={{color: "#788083"}}>Reset Password</h5>
                    <p className="mb-0" style={{color: "#788083"}}>Type in your new password</p>

                    {/*<a href="/" className="logo logo-admin mt-4">*/}
                    {/*  <img src={logo} alt="" height="30" />*/}
                    {/*</a>*/}
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">
                    {noMatch ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Password and Confirm Password do not match.
                        </Alert>
                    ) : null}
                    {emailFailed ? (
                      <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        Error in resetting password.
                      </Alert>
                    ) : null}
                    {emailSuccessful ? (
                      <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        Your password has been successfully reset
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                            name="confirmPassword"
                            label="Confirm Password"
                            className="form-control"
                            placeholder="Enter your password again"
                            type="password"
                            required
                        />
                      </div>
                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                          >
                            {
                              loading? "Sending...": "Reset"
                            }
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                  <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In
                                here</Link> </p>
                  <p>
                    © {new Date().getFullYear()} Quintessential Bank. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by Bein Tech Team
                </p>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ResetPasswordPage)
)
