import React, {useEffect, useState} from 'react';
import "react-activity/dist/library.css";
import {Spinner} from "reactstrap";
import Logo from "../../assets/images/logo-light.png"
import {useParams} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

const VerifyingEmail = () => {

    const [verified, setVerified] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [verifiedMsg, setVerifiedMsg] = useState(false)
    const [email, setEmail] = useState("")
    const [verificationFailed, setVerificationFailed] = useState(false)
    const [hasCheckedVerification, setHasCheckedVerification] = useState(false)

    const {code} = useParams();

    console.log({code})

    useEffect(() => {
        if (code?.length > 0){
            console.log("Here")
            fetch(`https://aang.quintessentialbank.com/api/email/verify/${code}`)
                .then(response => response.json())
                .then(response => {
                    console.log({response})
                    if (response?.status === "success"){
                        console.log("Here 2")
                        setVerified(true)
                        setTimeout(() => {
                            window.location.href = "/login"
                        },2000)
                    }
                    else{
                        setVerificationFailed(true)
                    }
                })
                .catch(error => {
                    console.log({error})
                    setVerificationFailed(true)
                })
        }
    },[])

    const resendVerification = async (email) => {
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/auth/email/verify/resend", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email}),
            });

            const result = await response.json();
            if (result?.status === "success"){
                setSuccessMsg(true)
            }
            else{
                setErrorMsg(true)
            }
            console.log("Success:", result);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    return (
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col text-center">
                    <img src={Logo} className="img-fluid" style={{
                        height: 250,
                        width: 250,
                        marginBottom: 30,
                        marginTop: 30,
                    }} />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col text-center mt-auto vh-100">
                    {
                        verificationFailed ?
                            <div className="mx-auto" style={{textAlign: 'center', width: 270}}>
                                <p style={{marginBottom: 30, fontSize: '2rem', color: 'black'}}>Email Verification Failed</p>
                                <input type="text" className="form-control mb-3" placeholder="Email"
                                       value={email}
                                       onChange={(e) => setEmail(e?.target.value)}
                                />
                                <button className="btn btn-lg btn-primary"
                                onClick={() => resendVerification(email)}>
                                    Resend Verification Code
                                </button>
                            </div> :
                            <>
                                <Spinner
                                    color="primary"
                                    style={{
                                        height: '7rem',
                                        width: '7rem',
                                        marginBottom: 30
                                    }}
                                >
                                    Loading...
                                </Spinner>
                                <h3>{verified? "Email verified, redirecting to Login Page" : "Verifying your email..."}</h3>
                            </>
                    }
                </div>
                {successMsg ? (
                    <SweetAlert
                        title="Email sent successfully"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccessMsg(false)
                            window.location.href = "/login"
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Check your email to verify your account!
                    </SweetAlert>
                ) : null}
                {errorMsg ? (
                    <SweetAlert
                        title="Error in sending Email Verification"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setErrorMsg(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Ensure the email you typed in exists!
                    </SweetAlert>
                ) : null}
                {verifiedMsg ? (
                    <SweetAlert
                        title="Email verified successfully"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setSuccessMsg(false)
                            window.location.href = "/login"
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        You will be redirected to the Login page!
                    </SweetAlert>
                ) : null}
            </div>
        </div>
    );
};

export default VerifyingEmail;
