import PropTypes from "prop-types"
import React, {useEffect, useRef, useState} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {

  const [fullName, setFullName] = useState("")
  const [user, setUser] = useState("")

  useEffect(() => {
    let res2 = localStorage.getItem("authUser")
    console.log({res2})
    setUser(JSON.parse(res2))
  },[])

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="vertical-simplebar" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/*<li className="menu-title">{props.t("Menu")} </li>*/}
            <li>
              <Link to="/#" className="waves-effect">
                <i className="mdi mdi-airplay"></i>
                {/*<span className="badge rounded-pill bg-info float-end">2</span>*/}
                <span>Home</span>
                {/*<span>{props.t("Dashboard")}</span>*/}
              </Link>
              {/*<ul className="sub-menu">*/}
              {/*  <li><Link to="/dashboard">{props.t("Dashboard")} 1</Link></li>*/}
              {/*  <li><Link to="/#">{props.t("Dashboard")} 2</Link></li>*/}
              {/*</ul>*/}
            </li>

            <li>
              <Link to="/profile" className=" waves-effect">
                <i className="mdi mdi-account-circle-outline"></i>
                <span>My Profile</span>
                {/*<span>{props.t("Calendar")}</span>*/}
              </Link>
            </li>

            <li>
              <Link to="/beneficiaries" className=" waves-effect">
                <i className="mdi mdi-account-card-details"></i>
                <span>Manage Beneficiaries</span>
                {/*<span>{props.t("Email")}</span>*/}
              </Link>
            </li>

            <li>
              <Link to="/transactions" className=" waves-effect">
                <i className="mdi mdi-inbox-full"></i>
                <span>Transaction History</span>
                {/*<span>{props.t("Email")}</span>*/}
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-send-check"></i>
                <span>Transfer</span>
                {/*<span>{props.t("Tasks")}</span>*/}
              </Link>
              <ul className="sub-menu">
                {/*<li><Link to="/#">Domestic Transfer</Link></li>*/}
                <li><Link to="/interbank-transfer">Inter Bank Transfer</Link></li>
                <li><Link to="/wire-transfer">Wire Transfer</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash-plus"></i>
                <span>Loans</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/loan-application">Apply for a Loan</Link>
                </li>
                <li>
                  <Link to="/loans">My Loans</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cash-usd-outline"></i>
                <span>Federal Reserves</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/fdr-application">FDR Plans</Link>
                </li>
                <li>
                  <Link to="/fdr">My FDR</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className=" waves-effect">
                <i className="mdi mdi-credit-card"></i>
                <span>Cards</span>
              </Link>
            </li>

            {JSON.parse(localStorage.getItem("authUser"))?.email === "admin@quintessentialbank.com" && <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box-multiple"></i>
                <span>Admin Section</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/manage-users">Manage Users</Link>
                </li>
                <li>
                  <Link to="/manage-fdr">Add/Edit FDR Plans</Link>
                </li>
                <li>
                  <Link to="/manage-loan">Add/Edit Loan Plan</Link>
                </li>
                <li>
                  <Link to="/manage-fdradmin">Manage Users FDR</Link>
                </li>
                <li>
                  <Link to="/manage-loanadmin">Manage Users Loan</Link>
                </li>
              </ul>
            </li>}

            <li>
              <Link onClick={() => localStorage.removeItem("authUser")} to="/login" className=" waves-effect">
                <i className="mdi mdi-logout"></i>
                <span>Logout</span>
              </Link>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
