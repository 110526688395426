import React, {useEffect, useState} from "react"
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Row} from "reactstrap"
import DomesticTransfer from "../../assets/images/icons/domestic transfer-01.svg"
import InterbankTransfer from "../../assets/images/icons/Interbank Transfer-01.svg"
import WireTransfer from "../../assets/images/icons/Wire transfer.svg"
import ActiveLoans from "../../assets/images/icons/active loan-01.svg"
import RunningFDR from "../../assets/images/icons/FDR-01.svg"
import Beneficiaries from "../../assets/images/icons/Beneficiary-01.svg"
import Logo from "../../assets/images/logo-light.png"

const Dashboard = () => {

  const [accountNumber, setAccountNumber] = useState("")
  const [balance, setBalance] = useState("")
  const [accountType, setAccountType] = useState("")
  const [status, setStatus] = useState("")
  const [currency, setCurrency] = useState("")

  const fetchAccountNumber = async () => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const email = obj?.email;

    let acct = await fetch(`https://aang.quintessentialbank.com/api/accounts/user/${email}`)
    acct = await acct.json();
    acct = acct.data[0];

    // console.log(acct)

    return acct;
  }

  useEffect(() => {
    fetchAccountNumber()
        .then(res => {
          console.log({res})
          setBalance(res?.balance)
          setAccountType(res?.accountType)
          setAccountNumber(res?.accountNumber)
          setCurrency(res?.currency)
        })

  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12 mb-4">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18"></h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to Quintessential Bank</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>

      </div>
      <div className="container ">
        <Row>
          <Col>
            <br/><br/>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card style={{backgroundColor: "#283d92"}} className="text-white mt-md-2 mt-sm-4">
              <CardBody>
                <div className="row">
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Account Number
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{accountNumber}</h2>
                  </div>
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Available Balance
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{currency}{Number(balance).toLocaleString("en-us")}</h2>
                  </div>
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Account Type
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{accountType?.toUpperCase()} (Active)</h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#1a73e8',
              background: 'linear-gradient(135deg, #1a73e8, #3f8efc)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/transactions"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-check-circle me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Transaction History
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={DomesticTransfer} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#34a853',
              background: 'linear-gradient(135deg, #34a853, #66cc74)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/interbank-transfer"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-arrow-right me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Inter Bank Transfers
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={InterbankTransfer} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#fbbc05',
              background: 'linear-gradient(135deg, #fbbc05, #ffd54f)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/wire-transfer"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-globe me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Wire Transfers
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={WireTransfer} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#e94235',
              background: 'linear-gradient(135deg, #e94235, #f06262)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/loan-application"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-money-stack me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Loans
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={ActiveLoans} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#6c757d',
              background: 'linear-gradient(135deg, #6c757d, #9ea7ad)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/fdr-application"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-bank me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Federal Reserves
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={RunningFDR} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card outline className="border" style={{
              cursor: 'pointer',
              borderColor: '#1e88e5',
              background: 'linear-gradient(135deg, #1e88e5, #42a5f5)',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              borderRadius: '8px'
            }}
                  onClick={() => window.location.href="/beneficiaries"}
                  onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
              <CardHeader style={{ backgroundColor: 'transparent', textAlign: 'center' }}>
                <h4 className="my-0" style={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                  <i className="uil uil-users-alt me-3" style={{ color: 'rgba(255, 255, 255, 0.8)' }}></i>Beneficiaries
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={Beneficiaries} style={{ width: 200, height: 100 }} />
              </CardBody>
            </Card>
          </Col>
        </Row>




      </div>
    </React.Fragment>
  )
}

export default Dashboard
