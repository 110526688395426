import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"

import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import {connect, useDispatch} from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-sm-dark.png"
import {setSignIn} from "../../redux/features/auth/authSlice";
import SweetAlert from "react-bootstrap-sweetalert";
import Logo from "../../assets/images/logo-dark.png"

const Login = (props) => {
  const dispatch = useDispatch();
  // const [loginSuccessfully, setEditedSuccessfully] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [accountBlocked, setAccountBlocked] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    setLoading(true)

    try {
      const response = await fetch("https://aang.quintessentialbank.com/api/login", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const result = await response.json();
      console.log("Result:", result);
      if (result?.status === "suspended" || result?.status === 'blocked'){
        setAccountBlocked(true)
        setLoading(false)
        return;
      }
      if (result?.loginToken){
        // dispatch(setSignIn(result))
        localStorage.setItem("authUser", JSON.stringify(result))
        setLoading(false)
        window.location.href = "/"
      }
      else{
        setLoginFailed(true)
        setLoading(false)
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false)
      setLoginFailed(true)
    }
    // props.loginUser(values, props.history)
    // dispatch(setSignIn());
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            {/*<Col className="text-center">*/}
            {/*  <img src={Logo} style={{width: 150, height: 150}} />*/}
            {/*</Col>*/}
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <img src={Logo} style={{width: 150, height: 150, alignSelf: 'center', marginTop: 30}} />
                {/*<div className="bg-login text-center">*/}
                {/*  /!*<div className="bg-login-overlay"></div>*!/*/}
                {/*  <div className="position-relative">*/}
                {/*    <h5 className="text-white font-size-20">Welcome Back !</h5>*/}
                {/*    <p className="text-white-50 mb-0">Sign in to continue to Quintessential Bank.</p>*/}
                {/*    <Link to="/" className="logo logo-admin mt-4">*/}
                {/*      <img src={logo} alt="" height="30" />*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="card-body pt-5">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          {
                            loading? "Loading": "Login"
                          }
                          {/*Log In*/}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i
                          className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                      </div>
                    </AvForm>

                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>Don't have an account ? <Link to="/register"
                  className="fw-medium text-primary"> Signup now </Link> </p>
                <p>© {new Date().getFullYear()} Quintessential Bank
                        </p>
              </div>
            </Col>
          </Row>
          {loginFailed ? (
              <SweetAlert
                  title="Error in Logging In"
                  warning
                  // showCancel
                  confirmButtonText="Ok"
                  confirmBtnBsStyle="error"
                  // cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setLoginFailed(false)
                    // setsuccess_dlg(true)
                    // setdynamic_title("Deleted")
                    // setdynamic_description("Your file has been deleted.")
                  }}
                  // onCancel={() => setconfirm_alert(false)}
              >
                Ensure you are using the right email and password combination!
              </SweetAlert>
          ) : null}
          {accountBlocked ? (
              <SweetAlert
                  title="Account temporarily disabled."
                  warning
                  // showCancel
                  confirmButtonText="Ok"
                  confirmBtnBsStyle="error"
                  // cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    setAccountBlocked(false)
                    // setsuccess_dlg(true)
                    // setdynamic_title("Deleted")
                    // setdynamic_description("Your file has been deleted.")
                  }}
                  // onCancel={() => setconfirm_alert(false)}
              >
                Please contact Customer Support or Admin!
              </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
// )

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}
