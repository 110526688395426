import React, {useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import {useMediaPredicate} from "react-media-hook";

const ManageFDR = () => {
    const [allBeneficiaries, setAllBeneficiaries] = useState([])
    const [myBeneficiaries, setMyBeneficiaries] = useState([])
    const [dataRows, setDataRows] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})
    const [nickname, setNickname] = useState("")
    const [accountName, setAccountName] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountType, setAccountType] = useState("")
    const [editedSuccessfully, setEditedSuccessfully] = useState(false)
    const [editFailed, setEditFailed] = useState(false)
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createFailed, setCreateFailed] = useState(false)
    const [deletedSuccessfully, setDeletedSuccessfully] = useState(false)
    const [deleteFailed, setDeleteFailed] = useState(false)
    const [users, setUsers] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [gender, setGender] = useState("")
    const [occupation, setOccupation] = useState("")
    const [ATMPin, setATMPin] = useState("")
    const [COTCode, setCOTCode] = useState("")
    const [IMFCode, setIMFCode] = useState("")
    const [ComplaintCode, setComplaintCode] = useState("")
    const [country, setCountry] = useState("")
    const [status, setStatus] = useState("")
    const [ForeignTransferPin, setForeignTransferPin] = useState("")
    const [DomesticTransferPin, setDomesticTransferPin] = useState("")
    const [balance, setBalance] = useState("")
    const [currency, setCurrency] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [FDRName, setFDRName] = useState("")
    // const [status, setStatus] = useState("")

    const editFormRef = useRef();
    const createFormRef = useRef();
    // const {width, height} = useViewport()
    const biggerThan768 = useMediaPredicate("(min-width: 760px)");

    console.log({biggerThan768})

    const columns = [
        {
            label: "User Email",
            field: "email",
            sort: "asc",
            width: 150,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Plan Name",
            field: "FDRName",
            sort: "asc",
            width: 270,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Date Applied",
            field: "date",
            sort: "asc",
            width: 200,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 100,
            attributes: { style: {fontWeight: '700'}}
        },
        {
            label: "Action",
            field: "action",
            width: 300,
            attributes: { style: {fontWeight: '700'}}
        },
    ]

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

    const getFDRs = async () => {
        let fdrs = await fetch(`https://aang.quintessentialbank.com/api/FDR/all/1/1000000`)
        fdrs = await fdrs.json()
        fdrs = fdrs.data?.docs

        fdrs = fdrs?.filter(el => el?.isCore === false)

        fdrs?.forEach((item, index) => {
            item.date = new Date(item?.date).toDateString()
            item.id = (
                <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.id}</div>
            );
            item.action = (
                <div style={{ display: "flex", justifyContent: "space-between", width: 150 }}>
                    <div
                        className="uil-trash-alt"
                        style={{
                            cursor: "pointer",
                            color: "white",
                            fontSize: ".7em",
                            padding: ".5rem",
                            borderRadius: ".3rem",
                            background: "#2f4395",
                            width: "90%",
                            textAlign: "center",
                            height: 30,
                        }}
                        onClick={() => selectUserToEdit(item?.email, item?.FDRName)}
                    >
                        Edit
                    </div>
                    {/*<div*/}
                    {/*    className="uil-trash-alt"*/}
                    {/*    style={{*/}
                    {/*        cursor: "pointer",*/}
                    {/*        color: "white",*/}
                    {/*        fontSize: ".7em",*/}
                    {/*        padding: ".5rem",*/}
                    {/*        borderRadius: ".3rem",*/}
                    {/*        background: "#fb6262",*/}
                    {/*        width: "50%",*/}
                    {/*        textAlign: "center",*/}
                    {/*        height: 30,*/}
                    {/*    }}*/}
                    {/*    onClick={() => selectUserToDelete(item?.email)}*/}
                    {/*>*/}
                    {/*    Delete*/}
                    {/*</div>*/}
                </div>
            );
        })

        return fdrs
    }

    useEffect(() => {
        getFDRs()
            .then(res => {
                console.log("Users: ",{res})

                setData({
                    columns,
                    rows: res
                })
            })
            .catch(err => {
                console.log({err})
            })
    },[])

    async function handleValidSubmit(event, values) {
        // const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log({values})
        let toPost = {
            email: values?.email,
            FDRName: values?.FDRName,
            payload: {status: values?.FDRStatus}
        }
        // let toPost2 = {
        //     accountNumber: accountNumber,
        //     // status: values?.accountNumber,
        //     payload: {...values, status: values?.accountStatus}
        // }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/FDR/edit-fdr", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setEditedSuccessfully(true)

            // const response2 = await fetch("https://aang.quintessentialbank.com/api/accounts/edit-account", {
            //     method: "POST", // or 'PUT'
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify(toPost2),
            // });
            //
            // const result2 = await response2.json();
            // setEditedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setEditFailed(true)
        }
    }


    async function handleValidCreate(event, values) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            email: obj?.email,
            accountNumber: values?.accountNumber,
            accountName: values?.accountName,
            accountType: values?.accountType,
            nickname: values?.nickname,
        }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/beneficiary/new", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setCreatedSuccessfully(true)
            console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setCreateFailed(true)
        }
    }

    async function handleDeleteBeneficiary(aNum) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(aNum)
        let toPost = {
            email: obj?.email,
            accountNumber: aNum,
            payload: { status: "deleted" }
        }
        try {
            const response = await fetch("https://aang.quintessentialbank.com/api/beneficiary/edit", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setDeletedSuccessfully(true)

            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setDeleteFailed(true)
        }
    }

    const selectUserToEdit = async (email, fdrname) => {
        let selectedFDR = await fetch(`https://aang.quintessentialbank.com/api/FDR/details/${email}/${fdrname}`)
        selectedFDR = await selectedFDR.json();
        selectedFDR = selectedFDR?.data[0]


        // console.log({selectedFDR})

        setUserEmail(email)
        setFDRName(fdrname)
        setStatus(selectedFDR?.status)

        toggleEditModal()

        // const obj = JSON.parse(localStorage.getItem("authUser"))
        // fetch(`https://aang.quintessentialbank.com/api/user/${obj?.email}`)
        //     .then(res => res.json())
        //     .then(res => {
        //         // console.log("nickname: ", res?.data)
        //         setNickname(res?.data[0]?.nickname)
        //         setAccountName(res?.data[0]?.accountName)
        //         setAccountNumber(res?.data[0]?.accountNumber)
        //         setAccountType(res?.data[0]?.accountType)
        //         toggleEditModal()
        //     })
        //     .catch(err => {
        //         console.log({err})
        //         toggleEditModal()
        //     })
        // setNickname()
    }

    const selectUserToDelete = (accountNumber) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://aang.quintessentialbank.com/api/beneficiary/details/${obj?.email}/${accountNumber}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setNickname(res?.data[0]?.nickname)
                setAccountName(res?.data[0]?.accountName)
                setAccountNumber(res?.data[0]?.accountNumber)
                setAccountType(res?.data[0]?.accountType)
                toggleDeleteModal()
            })
            .catch(err => {
                console.log({err})
                toggleDeleteModal()
            })
        // setNickname()
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Quintessential Bank" breadcrumbItem="Manage FDR" />
                {
                    !biggerThan768 &&
                    <Row>
                        <Col><br/><br/></Col>
                    </Row>
                }
                <Row>
                    <Col>
                        {/*<button className="btn btn-primary mb-3 float-end"*/}
                        {/*onClick={toggleCreateModal}>*/}
                        {/*    Add Beneficiary*/}
                        {/*</button>*/}
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showCreateModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Add Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={createFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidCreate(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="nickname"
                                                    label="Nickname"
                                                    value={nickname}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="accountName"
                                                    label="Account Name"
                                                    value={accountName}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="accountNumber"
                                                    label="Account Number"
                                                    value={accountNumber}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="accountType" label="Gender"
                                                         value={accountType}>
                                                    <option value="Checking">Checking</option>
                                                    <option value="Savings">Savings</option>
                                                    <option value="Fixed Deposit">Fixed Deposit</option>
                                                    <option value="Premium Finance Account">Premium Finance Account</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => createFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showEditModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Edit FDR
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={editFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                >
                                <Row>
                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <div className="form-group">
                                            <AvField
                                                name="email"
                                                label="User Email"
                                                value={userEmail}
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 mb-3">
                                        <div className="form-group">
                                            <AvField
                                                name="FDRName"
                                                label="FDR Name"
                                                value={FDRName}
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </Row>
                                    <Row>
                                        <div className="col-md-12 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="FDRStatus" label="FDR Status"
                                                         value={status} defaultValue={status}>
                                                    <option value="">Select a status</option>
                                                    <option value="running">Running</option>
                                                    <option value="suspended">Suspended</option>
                                                    <option value="closed">Closed/Completed</option>
                                                    <option value="deleted">Deleted</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => editFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showDeleteModal}
                            toggle={() => {
                                toggleDeleteModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Delete Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this beneficiary?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleDeleteBeneficiary(accountNumber)}
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                {editedSuccessfully ? (
                    <SweetAlert
                        title="Edit successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your FDR has been updated!
                    </SweetAlert>
                ) : null}
                {editFailed ? (
                    <SweetAlert
                        title="Error in editing FDR"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {deletedSuccessfully ? (
                    <SweetAlert
                        title="Delete successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeletedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your beneficiary has been deleted!
                    </SweetAlert>
                ) : null}
                {deleteFailed ? (
                    <SweetAlert
                        title="Error in deleting beneficiary"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeleteFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {createdSuccessfully ? (
                    <SweetAlert
                        title="Beneficiary Successfully added"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreatedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        A new beneficiary has been added!
                    </SweetAlert>
                ) : null}
                {createFailed ? (
                    <SweetAlert
                        title="Error in adding beneficiary"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreateFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
            </div>

        </React.Fragment>
    )
}

export default ManageFDR
