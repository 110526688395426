import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile , resetProfileFlag} from "../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert";

const UserProfile = props => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [gender, setGender] = useState("")
  const [nationality, setNationality] = useState("")
  const [countryOfResidence, setCountryOfResidence] = useState("")
  const [city, setCity] = useState("")
  const [st, setSt] = useState("")
  const [address, setAddress] = useState("")
  const [dmPin, setDmPin] = useState("")
  const [ftPin, setFtPin] = useState("")
  const [idx, setidx] = useState(1)
  const [editedSuccessfully, setEditedSuccessfully] = useState(false)
  const [editFailed, setEditFailed] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setEmail(obj?.email)
      setName(`${obj?.firstName} ${obj.lastName}`)
      setFirstName(obj?.firstName)
      setLastName(obj?.lastName)
      setGender(obj?.gender)
      setNationality(obj?.country);
      setCountryOfResidence(obj?.countryOfResidence);
      setCity(obj?.city);
      setSt(obj?.state)
      setAddress(obj?.address)
      setDmPin(obj?.DomesticTransferPin)
      setFtPin(obj?.ForeignTransferPin)
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success])

  async function handleValidSubmit(event, values) {
    console.log({values})

    console.log("Gender: ", document.getElementById("gender").value)
    let toPost = {
      ...values, ...{
        country: values?.nationality,
        DomesticTransferPin: values?.dmPin,
        ForeignTransferPin: values?.ftPin,
      }
    }

    try {
      const response = await fetch("https://aang.quintessentialbank.com/api/edit-user", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          payload: toPost
        }),
      });

      const result = await response.json();
      console.log({result})
      localStorage.removeItem("authUser")
      // window.location.href="/login"
      // console.log("Got here")
      setEditedSuccessfully(true)
      // console.log("Result:", result);
    } catch (error) {
      console.error("Error:", error);
      setEditFailed(true)
    }

    console.log({toPost})
  }

  return (
    <React.Fragment>
      <div className="page-content">

          {/* Render Breadcrumb */}
          <Breadcrumb title="Quintessential Bank" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success && props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      {/*<img*/}
                      {/*  src={avatar}*/}
                      {/*  alt=""*/}
                      {/*  className="avatar-md rounded-circle img-thumbnail"*/}
                      {/*/>*/}
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        {/*<p className="mb-0">Id no: #{idx}</p>*/}
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">View/Edit Profile Details</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="firstName"
                          label="First Name"
                          value={firstName}
                          className="form-control"
                          placeholder="Enter your given name"
                          type="text"
                          required
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="lastName"
                          label="Last Name"
                          value={lastName}
                          className="form-control"
                          placeholder="Enter family name/surname"
                          type="text"
                          required
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField type="select" name="gender" label="Gender"
                      value={gender} defaultValue={gender}>
                        <option value="">Select a gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                      </AvField>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <AvField
                          name="nationality"
                          label="Nationality"
                          value={nationality}
                          className="form-control"
                          placeholder="Enter your nationality"
                          type="text"
                          required
                      />
                      {/*<AvField name="idx" value={idx} type="hidden" />*/}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="countryOfResidence"
                          label="Country of Residence"
                          value={countryOfResidence}
                          className="form-control"
                          placeholder="Enter your country of residence"
                          type="text"
                          required
                      />
                      {/*<AvField name="idx" value={idx} type="hidden" />*/}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="city"
                          label="City"
                          value={city}
                          className="form-control"
                          placeholder="Enter your city"
                          type="text"
                          required
                      />
                      {/*<AvField name="idx" value={idx} type="hidden" />*/}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="state"
                          label="State"
                          value={st}
                          className="form-control"
                          placeholder="Enter your state"
                          type="text"
                          required
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="address"
                          label="Home Address"
                          value={address}
                          className="form-control"
                          placeholder="Enter your home address"
                          type="text"
                          required
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="dmPin"
                          label="Domestic Transfer Pin"
                          value={dmPin}
                          className="form-control"
                          placeholder="Enter your pin"
                          type="text"
                          required
                      />
                      {/*<AvField name="idx" value={idx} type="hidden" />*/}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="ftPin"
                          label="Foreign Transfer Pin"
                          value={ftPin}
                          className="form-control"
                          placeholder="Enter your pin"
                          type="text"
                          required
                      />
                      <AvField name="idx" value={idx} type="hidden" />
                    </div>
                  </div>
                </Row>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update My Profile
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        {editedSuccessfully ? (
            <SweetAlert
                title="Edit successful"
                success
                // showCancel
                confirmBtnBsStyle="success"
                // cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setEditedSuccessfully(false)
                  window.location.href="/login";
                }}
                // onCancel={() => {
                //     setSuccessMsg(false)
                // }}
            >
              Your profile has been updated! Please login to continue with updated details.
            </SweetAlert>
        ) : null}
        {editFailed ? (
            <SweetAlert
                title="Error in editing profile"
                warning
                // showCancel
                confirmButtonText="Ok"
                confirmBtnBsStyle="success"
                // cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setEditFailed(false)
                  // setsuccess_dlg(true)
                  // setdynamic_title("Deleted")
                  // setdynamic_description("Your file has been deleted.")
                }}
                // onCancel={() => setconfirm_alert(false)}
            >
              Please try again later!
            </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile , resetProfileFlag})(UserProfile)
)
